<template>
  <!-- Set Sales Tax Rate -->
  <ib-dialog
    id="dialog-set-sales-tax-rate"
    :visible="dialogSetSalesTaxRateVisible"
    fullscreen
    empty-header
    @open="openDialog"
    @close="closeDialog"
  >

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper">

      <!-- Header Flex -->
      <header class="header-flex">

        <!-- Header Title -->
        <h2>{{ $t('pages.businessGuide.revenueStreams.setSalesTaxRate') }}</h2>
        <!-- /Header Title -->

      </header>
      <!-- /Header Flex -->

      <!-- Guide -->
      <guide
        :visible="guideDialogVisible"
        :is-dialog="true"
        :labor="true"
        :title="$t('pages.businessGuide.revenueStreams.buttonDialog.setSaleTaxRate.title')"
        :text="$t('pages.businessGuide.revenueStreams.buttonDialog.setSaleTaxRate.text')"
        @click="toggleDialogGuide"
      />
      <!-- /Guide -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">

        <!-- Grid Wrapper -->
        <div class="grid-wrapper">
          <el-row :gutter="60">
            <el-col :md="12">

              <!-- Slider Block Item -->
              <div class="slider-block">

                <!-- Slider Block Title -->
                <h4>{{ $t('pages.businessGuide.staffAndPayroll.estimatedTaxRate') }}</h4>
                <!-- /Slider Block Title -->

                <!-- Slider Block Top -->
                <div class="top">
                  <div>
                    <p>{{ form.percentageValue }}%</p>
                  </div>
                </div>
                <!-- /Slider Block Top -->

                <!-- Slider Block Bottom -->
                <div class="bottom">
                  <el-slider
                    v-model="form.percentageValue"
                    :show-tooltip="false"
                    :min="0"
                    :max="100"
                    :step="1"
                  />
                </div>
                <!-- Slider Block Bottom -->

                <!-- Slider Block Range Values -->
                <div class="flex">
                  <p>0%</p>
                  <p>100%</p>
                </div>
                <!-- /Slider Block Range Values -->

                <!-- Slider Block Form -->
                <el-form v-model="form">

                  <!-- Slider Block How Often -->
                  <el-form-item
                    class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.howOftenWillYouPay')"
                    :class="{'focused' : focus.paymentFrequency}"
                  >

                    <!-- Slider Block How Often Select -->
                    <ib-select
                      v-model="form.paymentFrequency"
                      :popper-append-to-body="false"
                      popper-class="promo-dropdown"
                      :placeholder="$t('monthly')"
                      @focus="toggleFocus('paymentFrequency')"
                      @blur="toggleFocus('paymentFrequency')"
                      @change="toggleFocus('paymentFrequency')"
                    >
                      <ib-option :label="$t('monthly')" value="Monthly" />
                      <ib-option :label="$t('quarterly')" value="Quarterly" />
                      <ib-option :label="$t('annually')" value="Annually" />
                    </ib-select>
                    <!-- /Slider Block How Often Select -->

                  </el-form-item>
                  <!-- /Slider Block How Often -->

                </el-form>
                <!-- /Slider Block Form -->

              </div>
              <!-- /Slider Block Item -->

            </el-col>

            <!-- Revenue Streams On Which Taxes Are Applied -->
            <el-col>

              <!-- Revenue Streams On Which Taxes Are Applied Title -->
              <h4>{{ $t('pages.businessGuide.revenueStreams.selectRevenueStreams') }}</h4>
              <!-- /Revenue Streams On Which Taxes Are Applied Title -->

              <!-- Select All -->
              <div class="select-all">

                <!-- Select All Title -->
                <span>{{ $t('selectAll') }}</span>
                <!-- /Select All Title -->

                <!-- Select All Checkbox -->
                <el-checkbox v-model="checkAll" @input="handleCheckAllChange" />
                <!-- /Select All Checkbox -->

              </div>
              <!-- /Select All -->

              <!-- List of Revenue Streams On Which Taxes Are Applied Title And Their Checkboxes-->
              <div class="cards-wrapper">
                <el-row :gutter="10">
                  <el-col v-for="revenueStream in revenueStreams" :key="revenueStream.revenueStreamId" :md="12">
                    <div class="tax-options-item">

                      <!-- Revenue Stream Title -->
                      <span>{{ revenueStream.name }}</span>
                      <!-- /Revenue Stream Title -->

                      <!-- Revenue Stream Checkbox -->
                      <custom-checkbox
                        :checked="revenueStream.isTaxable"
                        @change="handleCheckedRevenueStreamsIsTaxableChange($event, revenueStream.revenueStreamId)"
                      />
                      <!-- /Revenue Stream Checkbox -->

                    </div>
                  </el-col>
                </el-row>
              </div>
              <!-- /List of Revenue Streams On Which Taxes Are Applied Title And Their Checkboxes-->

            </el-col>
            <!-- Revenue Streams On Which Taxes Are Applied -->

          </el-row>
        </div>
        <!-- /Grid Wrapper -->

      </div>
      <!-- /Inner Wrapper -->

      <!-- Save & Close -->
      <ib-dialog-footer-fullscreen
        :loading="loading"
        @submit="updateSalesTaxRate"
        @cancel="closeDialog"
      />
      <!-- /Save & Close -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Set Sales Tax Rate -->
</template>

<script>
import CustomCheckbox from '@/components/CustomCheckbox'
import Guide from '@/views/Home/StoryMode/Components/Guide/Guide'
import IbDialogFooterFullscreen from '@/components/_v2/Dialog/IbDialogFooterFullscreen'
import IbOption from '@/components/_v2/Select/IbOption'
import MixinDialog from '@/mixins/dialog'

export default {
  name: 'DialogSetSalesTaxRate',

  components: {
    CustomCheckbox,
    Guide,
    IbDialogFooterFullscreen,
    IbOption
  },

  mixins: [
    MixinDialog
  ],

  props: {
    dialogSetSalesTaxRateVisible: {
      type: Boolean,
      default: false
    },
    salesTaxRateAction: {
      type: Object || Array,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      guideDialogVisible: true,
      expandedDialogGuide: true,
      laborField: '',
      form: {
        id: 1,
        ideaId: this.$store.state.idea.id,
        percentageValue: 10,
        paymentFrequency: 'Monthly'
      },
      focus: {
        paymentFrequency: false
      },
      checkAll: false,
      checkedTaxOptions: [],
      revenueStreams: [],
      isIndeterminate: true
    }
  },

  computed: {},

  mounted () {
    this.checkInputLabels()
  },

  created () {},

  methods: {
    handleCheckAllChange (val) {
      this.revenueStreams.forEach((revenueStream) => {
        revenueStream.isTaxable = val
      })
    },

    handleCheckedRevenueStreamsIsTaxableChange (value, revenueStreamId) {
      if (this.checkAll) {
        this.checkAll = false
      }

      let numberOfUncheckedCheckedElements = 0

      this.revenueStreams.forEach((revenueStream) => {
        if (revenueStream.revenueStreamId === revenueStreamId) {
          revenueStream.isTaxable = value
        }
        numberOfUncheckedCheckedElements = !revenueStream.isTaxable ? numberOfUncheckedCheckedElements + 1 : numberOfUncheckedCheckedElements
      })

      this.checkAll = numberOfUncheckedCheckedElements < 1
    },

    toggleDialogGuide () {
      this.guideDialogVisible = !this.guideDialogVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]
      if (this.form[input]) this.focus[input] = true
    },

    closeDialog () {
      this.$emit('close-dialog-set-sales-tax-rate')
    },

    checkInputLabels () {
      this.checkInputLength('paymentFrequency')
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    openDialog () {
      if (this.salesTaxRateAction) {
        this.form = Object.assign({}, this.salesTaxRateAction)
        this.form.ideaId = this.$store.state.idea.id
      }

      let revenueStreamsCopy = []
      let numberOfUncheckedCheckedElements = 0

      if (this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams.length !== 0) {
        revenueStreamsCopy = this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams

        for (let i = 0; i < revenueStreamsCopy.length; i++) {
          this.revenueStreams[i] = {
            revenueStreamId: revenueStreamsCopy[i].id,
            isTaxable: revenueStreamsCopy[i].isTaxable,
            name: revenueStreamsCopy[i].name
          }
          numberOfUncheckedCheckedElements = !revenueStreamsCopy[i].isTaxable ? numberOfUncheckedCheckedElements + 1 : numberOfUncheckedCheckedElements
        }
      }

      this.checkAll = numberOfUncheckedCheckedElements < 1

      this.checkInputLabels()
    },

    updateSalesTaxRate () {
      this.loading = true
      this.$set(this.form, 'revenueStreams', this.revenueStreams)
      this.$http.put(`story-mode/forecast/revenue-stream/sales-tax-rate/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateSalesTaxRate', response.data.payload.salesTaxRate)
            this.form = this.$store.state.idea.storyMode.forecast.revenueStreams.salesTaxRate

            if (response.data.payload.revenueStreams) {
              const revenueStreams = response.data.payload.revenueStreams

              for (let i = 0; i < revenueStreams.length; i++) {
                this.$store.commit('idea/updateIsTaxableRevenueStream', revenueStreams[i])
              }
            }
          }
        }).finally(() => {
          this.loading = false
          this.scrollToTop()
          this.closeDialog()
        })
    }
  }
}
</script>
