<template>
  <!-- Dialog Remove Revenue Stream -->
  <ib-dialog
    :visible="dialogRemoveRevenueStreamVisible"
    :show-close="false"
    top="8vh"
    empty-header
    @close="closeDialogRemoveRevenueStream"
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/trash.svg')"
      :title="$t('dialogs.removeRevenueStream.title')"
      :subtitle="$t('dialogs.removeRevenueStream.subtitle')"
      :show-illustration="false"
    />
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :loading="loading"
        :submit-btn-text="$t('dialogs.removeRevenueStream.yesDeleteIt')"
        :cancel-btn-text="$t('dialogs.removeRevenueStream.notReally')"
        space-between-buttons
        variant-button="red"
        @cancel="closeDialogRemoveRevenueStream"
        @submit="removeRevenueStream"
      />
    </template>
    <!-- /Dialog Footer -->
  </ib-dialog>
  <!-- /Dialog Remove Revenue Stream -->
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DialogRemoveRevenueStream',

  props: {

    dialogRemoveRevenueStreamVisible: {
      default: false,
      type: Boolean
    },

    revenueStreamActionToDelete: {
      default: null,
      type: Object
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeRevenueStream'
    ]),

    closeDialogRemoveRevenueStream () {
      this.$emit('close-dialog-remove-revenue-stream')
    },

    removeRevenueStream () {
      this.loading = true
      const revenueStreamId = this.revenueStreamActionToDelete.id

      this.deleteStoryModeRevenueStream(revenueStreamId)
        .then(() => {
          this.$emit('on-update-revenue-streams-view')
          this.loading = false
        })

      this.$emit('close-dialog-remove-revenue-stream')
    }
  }
}
</script>
