<template>
  <label
    :id="id"
    class="el-checkbox"
    :class="[
      '',
      { 'is-disabled': isDisabled },
      { 'is-bordered': border },
      { 'is-checked': selfModel }
    ]"
  >
    <span class="el-checkbox__input"
          :class="{
            'is-disabled': isDisabled,
            'is-checked': selfModel,
            'is-indeterminate': indeterminate,
            'is-focus': focus
          }"
          :tabindex="indeterminate ? 0 : false"
          :role="indeterminate ? 'checkbox' : false"
          :aria-checked="indeterminate ? 'mixed' : false"
    >
      <span class="el-checkbox__inner" />
      <input
        v-if="trueLabel || falseLabel"
        v-model="selfModel"
        class="el-checkbox__original"
        type="checkbox"
        :aria-hidden="indeterminate ? 'true' : 'false'"
        :name="name"
        :disabled="isDisabled"
        :true-value="trueLabel"
        :false-value="falseLabel"
        @change="handleChange"
        @focus="focus = true"
        @blur="focus = false"
      >
      <input
        v-else
        v-model="selfModel"
        class="el-checkbox__original"
        type="checkbox"
        :aria-hidden="indeterminate ? 'true' : 'false'"
        :disabled="isDisabled"
        :value="label"
        :name="name"
        @change="handleChange"
        @focus="focus = true"
        @blur="focus = false"
      >
    </span>
    <span v-if="$slots.default || label" class="el-checkbox__label">
      <slot />
      <template v-if="!$slots.default">{{ label }}</template>
    </span>
  </label>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter'

export default {
  name: 'CustomCheckbox',

  mixins: [Emitter],

  inject: {
    elForm: {
      default: ''
    },
    elFormItem: {
      default: ''
    }
  },

  props: {
    checked: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    indeterminate: Boolean,
    disabled: Boolean,
    name: {
      type: String,
      default: ''
    },
    trueLabel: {
      type: String,
      default: ''
    },
    falseLabel: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    controls: {
      type: String,
      default: ''
    },
    border: Boolean,
    size: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      selfModel: false,
      focus: false,
      isLimitExceeded: false
    }
  },

  computed: {
    model: {
      get () {
        return this.selfModel
      },

      set (val) {
        this.$emit('input', val)
        this.selfModel = val
      }
    },

    isGroup () {
      return false
    },

    store () {
      return this.value
    },

    isDisabled () {
      return this.disabled
    }
  },

  watch: {
    'checked' () {
      this.selfModel = !!this.checked
    }
  },

  mounted () {
    if (this.indeterminate) {
      this.$el.setAttribute('aria-controls', this.controls)
    }
  },

  created () {
    this.selfModel = this.checked
  },

  methods: {
    handleChange (ev) {
      if (this.isLimitExceeded) return
      const value = !!ev.target.checked

      this.selfModel = value
      this.$emit('change', value, ev)
    }
  }
}
</script>
