<template>
  <chart-component>
    <!-- Title -->
    <template #title>
      {{ $t('charts.revenueDistribution') }}
    </template>
    <!-- /Title -->
    <!-- Subtitle -->
    <template #subtitle>
      {{ $t('pages.businessGuide.monthlyAverage') }}
    </template>
    <!-- /Subtitle -->
    <!-- Chart -->
    <template #chart>
      <revenue-distribution-chart
        hide-title
        format-labels
        :chart-animations="chartAnimations"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <total-amount
          class="mb-3"
          :amount="totalMonthlyAverage"
          :text="$t('totalMonthlyAverage')"
        />
        <div>
          <p class="m-0 text-small mb-4">
            {{ $t('topThreeAverage') }}
          </p>
          <template v-for="(topRevenueStream, index) in topRevenueStreams">
            <year-info
              :key="topRevenueStream.id"
              class="mb-3"
              circle
              :avatar-text="index + 1"
              :money="topRevenueStream[1]"
              :text="topRevenueStream[0]"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
    <!-- Right side -->
  </chart-component>
</template>

<script>
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import MixinCurrencies from '@/mixins/currencies'
import RevenueDistributionChart from '@/views/Home/StoryMode/Forecast/RevenueStreams/Charts/RevenueDistributionChart'
import TotalAmount from '@/views/Home/StoryMode/FinancialPlan/Components/TotalAmount'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

export default {
  name: 'RevenueDistribution',

  components: {
    ChartComponentRightSide,
    TotalAmount,
    YearInfo,
    ChartComponent,
    RevenueDistributionChart
  },

  mixins: [MixinCurrencies],

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    totalMonthlyAverage () {
      let result = 0

      if (this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown.total) {
        result = this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown.total
      }

      return result
    },

    topRevenueStreams () {
      let sorted = []

      if (this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown.sorted) {
        sorted = this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown.sorted
      }

      return sorted
    }
  }
}
</script>
